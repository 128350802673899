import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function Link({ to, ...props }) {
  const { i18n } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HashLink to={`/${i18n.language}${to}`} smooth {...props} />
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Link;
