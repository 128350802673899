/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

let cursorActive = false;
let mouseDown = false;
let mouseX = 0;
let mouseY = 0;

const StyledBubble = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: fixed;
  background-color: transparent;
  z-index: 999;
  pointer-events: none;

  transition: all ease-in-out 100ms, left ease-in-out 0ms, top ease-in-out 0ms;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: invert(100%);
    backdrop-filter: invert(100%);
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255, 255, 255, .8);
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const boundingLimits = [];

function Bubble() {
  let animFrame = -1;

  const [mouseState, setMouseState] = useState({
    x: 0, y: 0, mouseDown: false, cursorActive: false,
  });

  const calculateBoundingBox = () => {
    boundingLimits.splice(0, boundingLimits.length);

    for (const el of document.getElementsByClassName('hover-container')) {
      boundingLimits.push(el.getBoundingClientRect());
    }
  };

  const calculateCursorState = () => {
    const x = mouseX + document.body.scrollLeft;
    const y = mouseY + document.body.scrollTop;

    // eslint-disable-next-line max-len
    cursorActive = boundingLimits.filter((b) => b.top <= y && b.bottom >= y && b.left <= x && b.right >= x).length !== 0;

    if (animFrame === -1) {
      animFrame = requestAnimationFrame(() => {
        setMouseState({
          x: mouseX - 40,
          y: mouseY - 40,
          cursorActive,
          mouseDown: cursorActive && mouseDown,
        });

        animFrame = -1;
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;

      calculateCursorState();
    });

    document.addEventListener('mousedown', () => {
      mouseDown = true;

      calculateCursorState();
    });

    document.addEventListener('mouseup', () => {
      mouseDown = false;

      calculateCursorState();
    });

    window.addEventListener('resize', () => {
      calculateBoundingBox();
      calculateCursorState();
    });

    window.addEventListener('scroll', () => {
      calculateBoundingBox();
      calculateCursorState();

      setTimeout(() => {
        calculateBoundingBox();
        calculateCursorState();
      }, 650);
    });

    calculateBoundingBox();
    calculateCursorState();

    setTimeout(() => {
      calculateBoundingBox();
      calculateCursorState();
    }, 300);
  }, []); // eslint-disable-line

  return (
    <StyledBubble
      style={{
        left: `${mouseState.x}px`,
        top: `${mouseState.y}px`,
        // eslint-disable-next-line no-nested-ternary
        transform: mouseState.cursorActive ? (mouseState.mouseDown ? 'scale(0.7)' : 'scale(1.0)') : 'scale(0.1)',
      }}
    />
  );
}

export default Bubble;
