import React from 'react';
import styled from 'styled-components';
import Link from './shared/link';

import Logo from './shared/logo';
import LogoOtlak from './shared/logo-otlak';

const StyledFooter = styled.footer`
  padding: 20px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  font-size: 14px;
`;

const StyledLogos = styled.div`
  display: flex;
  transition: all ease-in-out 450ms;

  @media screen and (max-width: 900px) {
    flex-flow: column-reverse nowrap;

    &>a {
      padding-top: 40px;
    }
  }

  @media screen and (min-width: 901px) {
    flex-flow: row nowrap;

    transform: translateX(-70px);

    &:hover {
      transform: translateX(0%);
    }

    &>a {
      margin: 0 14px;

      transition: all ease-in-out 450ms;
      transform-origin: center;
    }

    &>a:nth-child(1) {
      cursor: pointer;

      transform: translateX(100%);
      margin: 0px;
      opacity: 0;
    }

    &:hover>a:nth-child(1) {
      margin: 0 14px;
      transform: translateX(0);

      opacity: 1;
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <div>
        2022 &copy; All rights reserved
      </div>

      <StyledLogos>
        <a className="hover-container" href="//otlakyazilim.com" target="_blank" rel="noreferrer">
          <LogoOtlak bottomFill="#fff" style={{ maxWidth: '140px', marginTop: '0px' }} />
        </a>

        <Link className="hover-container" to="/#main">
          <Logo bottomFill="#fff" style={{ maxWidth: '140px', marginTop: '0px' }} />
        </Link>
      </StyledLogos>
    </StyledFooter>
  );
}

export default Footer;
