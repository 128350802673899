import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navbar from './navbar';
import Footer from './footer';
import Link from './shared/link';
import Bubble from './shared/bubble';

import '@fortawesome/fontawesome-free/css/all.min.css';
import '../assets/scss/main.scss';

const SocialMedia = styled.div`
  position: fixed;
  left: 30px;
  bottom: 30px;

  display: flex;
  flex-flow: column nowrap;
  color: white;
  text-decoration: none;

  @media screen and (max-width: 900px) {
    display: none;
  }

  a {
    margin: 15px 0;
    cursor: pointer;
    color: white;

    &>i {
      transition: all 250ms ease-in-out;
    }

    &:hover, &:focus, &:active {
      color: white;
      text-decoration: none;

      &>i {
        transform: scale(1.3);
      }
    }
  }
`;

const ScrollToTop = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;

  display: flex;
  flex-flow: column nowrap;
  display: none;

  @media screen and (min-width: 901px) {
    &.show {
      display: block;
    }
  }

  a {
    margin: 15px 0;
    cursor: pointer;
    color: white;
    text-decoration: none;

    &>i {
      transition: all 250ms ease-in-out;
    }

    &:hover, &:focus, &:active {
      text-decoration: none;

      &>i {
        transform: scale(1.1);
      }
    }
  }
`;

const ScrollDown = styled.div`
  position: fixed;
  right: -30px;
  bottom: 100px;

  display: none;

  @media screen and (min-width: 901px) {
    &.show {
      display: block;
    }
  }

  &>a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    transform-origin: 50% 50%;
    transform: rotate(-90deg);

    color: white;
    text-decoration: none;

    transition: all 250ms ease-in-out;

    font-size: 20px;

    &:focus, &:hover, &:active {
      text-decoration: none;

      transform: rotate(-90deg) scale(1.1);
    }

    &>span {
      display: block;
    }

    &>div {
      display: block;
      width: 50px;
      height: 1.3px;
      background-color: white;

      margin-left: 7px;
    }
  }
`;

const StyledMain = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .container-sections {
    display: flex;
    flex-flow: column nowrap;
  }
`;

function Layout({ children, expanded, visible }) {
  return (
    <div>
      <Navbar visible={visible} expanded={expanded} />
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <StyledMain>
          {children}
        </StyledMain>
      </div>

      <SocialMedia className="anim" style={{ opacity: visible ? '1' : '0' }}>
        <a className="hover-container" href="//facebook.com/PinqGames" rel="noopener noreferrer" target="_blank">
          <i className="fab fa-lg fa-facebook-f" />
        </a>

        <a className="hover-container" href="//linkedin.com/company/pinq-games" rel="noopener noreferrer" target="_blank">
          <i className="fab fa-lg fa-linkedin-in" />
        </a>
      </SocialMedia>

      <div className="anim" style={{ opacity: visible ? '1' : '0' }}>
        <ScrollDown className={expanded ? 'hover-container' : 'show hover-container'}>
          <Link to="/#about">
            <span>scroll down</span>
            <div />
          </Link>
        </ScrollDown>

        <ScrollToTop className={expanded ? 'show hover-container' : 'hover-container'}>
          <Link to="/#main"><i className="fas fa-2x fa-angle-up" /></Link>
        </ScrollToTop>
      </div>

      <Bubble />

      <Footer visible={visible} expanded={expanded} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Layout;
