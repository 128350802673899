import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
`;

function LogoOtlak({ style }) {
  return (
    <StyledSVG viewBox="0 0 600 200" enableBackground="new 0 0 600 200" style={style || {}}>
      <g style={{ fill: '#ffffff' }}>
        <path d="M177.84,93.38q0-14.34,6.11-25.24a45.38,45.38,0,0,1,16.83-17.21,49.35,49.35,0,0,1,48.29,0,45.58,45.58,0,0,1,16.74,17.21q6.12,10.9,6.12,25.24a50.44,50.44,0,0,1-6.12,25,45.5,45.5,0,0,1-16.74,17.31,49.35,49.35,0,0,1-48.29,0A45.29,45.29,0,0,1,184,118.34,50.44,50.44,0,0,1,177.84,93.38Zm13.38-.19a41.21,41.21,0,0,0,4.31,19.22,32.9,32.9,0,0,0,58.71,0,41.21,41.21,0,0,0,4.3-19.22,40.21,40.21,0,0,0-4.3-18.93,33.42,33.42,0,0,0-11.86-13,34.42,34.42,0,0,0-35,0,33.32,33.32,0,0,0-11.85,13A40.21,40.21,0,0,0,191.22,93.19Z" />
        <path d="M281.68,47.1h50.11V58.19H281.68Zm31.56,92.57h-13V17.84h13Z" />
        <path d="M362.77,139.67h-13.2V.25h13.2Z" />
        <path d="M417.47,142q-14.73,0-23.05-7.65t-8.32-19.7q0-12.81,9.18-20.46t25.44-9l30.6-2.49V79.61q0-9.18-3.26-14.44a17.43,17.43,0,0,0-8.79-7.36,34.89,34.89,0,0,0-12.43-2.1q-12.24,0-18.94,5.16t-6.69,14.54h-12a27.77,27.77,0,0,1,4.78-16.26,31.33,31.33,0,0,1,13.39-10.71,48.54,48.54,0,0,1,19.89-3.82,46,46,0,0,1,19.12,3.72,29.68,29.68,0,0,1,13.1,11.48q4.88,7.74,4.88,20v59.87H453.23l-1.72-16.26a30,30,0,0,1-13.29,13.67A43.23,43.23,0,0,1,417.47,142Zm3.06-10.71q14.72,0,22.75-9.37t8-24.29v-5L424,94.72q-13,1.16-18.74,6.5a17.14,17.14,0,0,0-5.74,13q0,8.42,5.74,12.72T420.53,131.25Z" />
        <path d="M504.48,139.67h-13.2V.25h13.2V94.91l45.9-47.81H567L531.45,84l35.76,55.66h-15.3L522.46,93.38l-18,18.55Z" />
        <path d="M111.52,0H15A15,15,0,0,0,0,15V127a15,15,0,0,0,15,15H53.32s.18-30.15-27.11-57.32a1.77,1.77,0,0,1,2.06-2.84,67.59,67.59,0,0,1,29.38,31A1.77,1.77,0,0,0,61,112.1c.7-13.87-.67-47.51-23.25-92.36a1.77,1.77,0,0,1,3-1.92c11.12,13.88,28.63,43,31.51,90.86a1.77,1.77,0,0,0,3.43.51c4-10.87,11.41-28.41,19.79-34.43a1.77,1.77,0,0,1,2.65,2.16C93,88.52,79.21,121.65,77.7,142h33.82a15,15,0,0,0,15-15V15A15,15,0,0,0,111.52,0Z" />
      </g>
    </StyledSVG>
  );
}

LogoOtlak.defaultProps = {
  style: undefined,
};

LogoOtlak.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default LogoOtlak;
