import React from 'react';
import { useTranslation } from 'react-i18next';

import FadeSection from '../shared/fade-section';

function AboutSection() {
  const { t } = useTranslation();

  return (
    <div id="about" className="container-sections">
      <FadeSection>
        <span className="title pinq  hover-container">
          {t('who-we-are')}
        </span>

        <div className="content hover-container">
          {t('about-pinq')}
          <br />
          {t('about-pinq-2')}
        </div>
      </FadeSection>

      {/* <FadeSection>
      <span className="title pinq" style={{ marginTop: '10px' }}>
        {t('what-we-do')}
      </span>

      <ul className="content">
        <li>{t('mobile-app-design')}</li>
        <li>{t('web-design')}</li>
        <li>{t('graphic-design')}</li>
        <li>{t('digital-strategy')}</li>
        <li>{t('frontend-development')}</li>
        <li>{t('backend-development')}</li>
      </ul>
    </FadeSection> */}
    </div>
  );
}

export default AboutSection;
