import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FadeSection from '../shared/fade-section';

const SocialMedia = styled.div`
  display: flex;
  flex-flow: row nowrap;
  color: white;
  text-decoration: none;
  padding-top: 50px;

  @media screen and (min-width: 901px) {
    display: none;
  }

  a {
    margin: 0 30px;
    cursor: pointer;
    color: white;

    &>i {
      transform: scale(1.5);
    }

    &:hover, &:focus, &:active {
      color: white;
      text-decoration: none;
    }
  }
`;

function ContactSection() {
  const { t } = useTranslation();

  return (
    <div id="contact" className="container-sections">
      <FadeSection>
        <span className="title pinq hover-container">
          {t('contact')}
        </span>

        <div className="content hover-container">
          <a id="contact-button" href="mailto:info@pinq.games">info@pinq.games</a>
        </div>

        <SocialMedia>
          <a className="hover-container" href="//facebook.com/PinqGames" rel="noopener noreferrer" target="_blank">
            <i className="fab fa-lg fa-facebook-f" />
          </a>

          <a className="hover-container" href="//linkedin.com/company/pinq-games" rel="noopener noreferrer" target="_blank">
            <i className="fab fa-lg fa-linkedin-in" />
          </a>
        </SocialMedia>
      </FadeSection>
    </div>
  );
}

export default ContactSection;
