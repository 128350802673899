import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import Link from '../shared/link';
import helmetImage from '../../assets/images/helmet.png';

const StyledMouse = styled.div`
  width: 30px;
  height: 50px;

  border: 1px solid #fff;
  border-radius: 50px;

  cursor: pointer;

  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);

  text-align: center;

  background-color: black;
  transition: padding 0.1s ease-in-out, opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;

  @media screen and (min-width: 900px) {
    display: none;
  }

  &:hover {
    background-color: white;

    &>* {
      background-color: black;
    }
  }
`;

const StyledWheelAnimation = keyframes`
  0% {
    margin-top: 35px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 10px;
    opacity: 0;
  }
`;

const StyledWheel = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;

  margin: 0 auto;

  display: block;

  animation: ${StyledWheelAnimation} 1.6s ease infinite;

  border: 1px solid #fff;

  transition: padding 0.1s ease-in-out, opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;
`;

const StyledMain = styled.div`
  position: relative;
  min-height: 100vh;

  text-align: center;
  opacity: 1;

  @media screen and (min-width: 901px) {
    &.hide {
      opacity: 0;
    }
  }

  &>.svg-container {
    margin: 0 auto;

    position: relative;
    top: 50vh;

    transform: translateY(-50%);

    img {
      max-width: 37vmin;
      transition: all ease-in-out 250ms;
      z-index: 800;
    }

    img:hover {
      transform: scale(1.1);
    }
  }
`;

function MainSection({ visible, expanded }) {
  return (
    <StyledMain id="main" className={`container-sections anim ${!visible || expanded ? 'hide' : ''}`}>
      <div className="svg-container">
        <img className="hover-container" src={helmetImage} alt="" />
      </div>

      <Link to="/#about">
        <StyledMouse>
          <StyledWheel />
        </StyledMouse>
      </Link>

    </StyledMain>
  );
}

MainSection.propTypes = {
  visible: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default MainSection;
