import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FadeSection from '../shared/fade-section';

import image1 from '../../assets/images/politon_banner.png';

const GamesGrid = styled.div`
  padding-top: 20px;
  min-height: 50vh;

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    &>a {
      &>div {
        max-height: 160px;
        height: 100%;

        &:nth-child(1) { grid-area: 1 / 1 / 5 / 5; }
        &:nth-child(2) { grid-area: 2 / 1 / 3 / 2; }
        &:nth-child(3) { grid-area: 3 / 1 / 4 / 2; }
        &:nth-child(4) { grid-area: 4 / 1 / 5 / 2; }
      }
    }
  }

  @media screen and (min-width: 901px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    &>a {
      max-height: 500px;

      &>div {
        height: 100%;
        max-height: 100%;
        &:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
      }
    }
    
  }

  &>a {
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    &>div {
      height: 100%;
      max-height: 100%;
      object-fit: cover;

      transition: all 250ms ease-in-out;

      padding: 0;

      @media screen and (min-width: 901px) {
        padding: 0;
      }

      &:hover, &:active, &:focus {
      //  transform: scale(1.05);
      }

      &>img {
        width: 100%;
        max-width: 100%;

        height: 100%;
        max-height: 100%;

        object-fit: contain;
        object-position: top left;
      }
    }
  }
`;

function GamesSection() {
  const { t } = useTranslation();

  return (
    <div id="games" className="container-sections">
      <FadeSection>
        <span className="title pinq">
          {t('games')}
        </span>

        <div className="content" style={{ width: '100%' }}>
          <GamesGrid>
            <a className="hover-container" href="https://politon.online" target="_blank" rel="noreferrer">
              <div style={{ backgroundColor: '##00000000' }}>
                <img src={image1} alt="" />
              </div>
            </a>
          </GamesGrid>
        </div>
      </FadeSection>
    </div>
  );
}

export default GamesSection;
