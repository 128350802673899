import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledFadeSection = styled.div`
  margin: 100px 5vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  opacity: 0;
  visibility: hidden;

  transition: opacity 0.6s ease-out;
  will-change: opacity, visibility;

  @media screen and (max-width: 900px) {
    margin: 70px 10px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    text-align: center;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &>span.title {
    flex-basis: 17%;
    font-size: 28px;
    line-height: 65px;
    font-weight: 300;

    @media screen and (max-width: 900px) {
      margin-bottom: 30px;
    }
  }

  &>div.content {
    flex-basis: 83%;
    font-size: 40px;
    line-height: 60px;
    font-weight: 300;

    @media screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &>ul.content {
    flex-basis: 83%;
    font-size: 35px;

    list-style: none;

    display: flex;
    flex-flow: row wrap;

    font-weight: 300;

    margin: 0;
    padding: 0;

    @media screen and (max-width: 900px) {
      font-size: 18px;
    }

    &>li {
      flex-basis: 50%;
      font-weight: 300;

      margin: 20px 0;
    }

    @media screen and (max-width: 900px) {
      text-align: center;

      &>li {
        flex-basis: 100%;
      }
    }
  }
`;

function FadeSection({ children, className, ...props }) {
  return (
    <StyledFadeSection
      className={`${className} visible`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </StyledFadeSection>
  );
}

FadeSection.defaultProps = {
  className: '',
};

FadeSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FadeSection;
