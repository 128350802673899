import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import favicon from '../assets/images/favicon.png';
import faviconDark from '../assets/images/favicon_dark.png';

function SEO({
  description, lang, meta, title,
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="%s | Pinq Site"
      meta={[
        {
          name: 'theme-color',
          content: '#000000',
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
    >
      <link id="favicon" rel="icon" href={favicon} type="image/png" media="(prefers-color-scheme:no-preference)" />
      <link id="favicon-light" rel="icon" href={favicon} type="image/png" media="(prefers-color-scheme:light)" />
      <link id="favicon-dark" rel="icon" href={faviconDark} type="image/png" media="(prefers-color-scheme:dark)" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
