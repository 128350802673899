import React, { useEffect, useState } from 'react';

import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';

import MainSection from '../components/sections/main';
import AboutSection from '../components/sections/about';
import ContactSection from '../components/sections/contact';
import GamesSection from '../components/sections/games';

function HomePage() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  if (!params.locale) {
    navigate(`/en${location.pathname}${location.hash}`);
  } else if (params.locale === 'en' && i18n.language !== 'en') {
    i18n.changeLanguage('en');
  } else if (params.locale === 'tr' && i18n.language !== 'tr') {
    i18n.changeLanguage('tr');
  }

  const [expanded, setExpanded] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    setExpanded(document.scrollingElement.scrollTop >= 300);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 250);
  });

  return (
    <Layout expanded={expanded} visible={visible}>
      <SEO title="Home" />

      <MainSection expanded={expanded} visible={visible} />
      <AboutSection expanded={expanded} visible={visible} />
      <GamesSection expanded={expanded} visible={visible} />
      <ContactSection expanded={expanded} visible={visible} />
    </Layout>
  );
}

export default HomePage;
