/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as NativeLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from './shared/logo';
import Link from './shared/link';

const StyledNavbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 0 30px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  background-color: #000;

  &>div {
    transition: all 500ms ease-in-out;

    &.left {
      @media screen and (max-width: 900px) {
        display: none;
      }

      .navbar-logo {
        height: 100px;

        display: inline-block;

        opacity: 0;
        width: 0;
        padding: 0;
        height: 100px;

        transition: all 700ms ease-in-out;
        transform-origin: center;
      }
    }

    &.float {
      @media screen and (max-width: 900px) {
        display: none;
        visibility: hidden;
      }
    }

    &.right {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      width: auto;

      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(50%);

      background-color: black;

      @media screen and (max-width: 900px) {
        display: flex;
        flex-flow: column nowrap;

        padding-top: 40px;
        width: 100%;
        height: 100vh;

        transform: translateX(50%) translateY(-150%);
      }

      &>.change-lang-button {
        display: none;
        visibility: hidden;

        @media screen and (max-width: 900px) {
          display: inline-block;
          visibility: visible;

          position: absolute;

          padding: 0px;

          left: 30px;
          top: 30px;
        }

        cursor: pointer;

        text-decoration: none;
        color: white;

        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }

    a {
      color: white;
      text-decoration: none;
      outline: none;

      white-space: nowrap;

      font-size: 20px;

      @media screen and (max-width: 900px) {
        font-size: 30px;
      }

      padding: 36px 36px;

      transition: all 250ms ease-in-out;

      text-align: center;

      &:hover {
        color: white;
        text-decoration: none;
        outline: none;

        transform: scale(1.15);
      }

      &:active {
        color: white;
        text-decoration: none;
        outline: none;

        transform: scale(0.95);
      }
    }
  }

  @media screen and (min-width: 901px) {
    &.expanded {
      &>div {
        &.left {
          .navbar-logo {
            opacity: 1;
            width: 14vw;
            padding: 30px;
          }
        }

        &.right {
          right: 0%;
          transform: translateX(0%);
        }
      }
    }
  }

  &>.navbar-toggle {
    display: inline-block;

    position: absolute;
    right: 30px;
    top: 30px;

    cursor: pointer;

    @media screen and (min-width: 901px) {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    &.open {
      &>.right {
        transform: translateX(50%) translateY(0%);
      }
    }
  }
`;

function Navbar({ visible, expanded }) {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <StyledNavbar
      id="navbar"
      className={`anim ${expanded ? 'expanded' : ''} ${isMenuOpen ? 'open' : ''}`}
      style={{ opacity: visible ? '1' : '0' }}
    >

      <div className="left">
        <Link
          to="/#main"
          className="navbar-logo hover-container"
          onClick={() => setMenuOpen(false)}
        >
          <Logo bottomFill="#000000" style={{ maxWidth: '140px' }} viewBoxWidth={90} />
        </Link>
      </div>

      <div className="float">
        <NativeLink
          className="hover-container"
          to={i18n.language === 'tr' ? '/' : '/tr/'}
          onClick={() => setMenuOpen(false)}
        >
          <span className={i18n.language === 'tr' ? 'pinq' : ''}>TR</span>
          /
          <span className={i18n.language !== 'tr' ? 'pinq' : ''}>EN</span>
        </NativeLink>
      </div>

      <div className="right">
        <NativeLink
          className="change-lang-button hover-container"
          to={i18n.language === 'tr' ? '/' : '/tr/'}
          onClick={() => setMenuOpen(false)}
        >
          <span className={i18n.language === 'tr' ? 'pinq' : ''}>TR</span>
          /
          <span className={i18n.language !== 'tr' ? 'pinq' : ''}>EN</span>
        </NativeLink>

        <Link
          className="navbar-item hover-container"
          to="/#about"
          onClick={() => setMenuOpen(false)}
        >
          {t('who-we-are')}
        </Link>

        <Link
          className="navbar-item"
          to="/#games"
          onClick={() => setMenuOpen(false)}
        >
          {t('games')}
        </Link>

        <Link
          className="navbar-item hover-container"
          to="/#contact"
          onClick={() => setMenuOpen(false)}
        >
          {t('contact')}
        </Link>
      </div>

      <div
        className="navbar-toggle hover-container"
        onClick={() => setMenuOpen(!isMenuOpen)}
      >
        <svg width="30px" viewBox="0 0 59.63 43.87">
          <g>
            <rect style={{ fill: '#fff' }} width="59.63" height="2.45" />
            <rect style={{ fill: '#fff' }} y="20.71" width="59.63" height="2.45" />
            <rect style={{ fill: '#fff' }} y="41.41" width="59.63" height="2.45" />
          </g>
        </svg>
      </div>
    </StyledNavbar>
  );
}

Navbar.propTypes = {
  expanded: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Navbar;
